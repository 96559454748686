
.active-button {
    background: #F47C21;
    color: white;
    border: none;
}

.active-button:hover {
    color: white;
}


    .fields{
        margin-bottom: 20px;
    }
