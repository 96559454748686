.sort-view-wrapper {
    position: relative;
    padding-left: 2px;
}

.sort-icon-wrapper {
    display: inline-block;
    vertical-align: middle;
}

.sort-icon {
    cursor: pointer;
    width: 12px;
    height: 9px;
    display: inline-block;
    vertical-align: top;
}

.sort-arrow-up {
    right: 0;
    background: url("./../../../assets/images/up_selected.png") no-repeat;
}

.sort-arrow-down {
    position: absolute;
    background: url("./../../../assets/images/down_selected.png") no-repeat;
    top: 8px;
    right: 0;
}

.sort-arrow-up.deactive {
    background: url("./../../../assets/images/up_unselected.png") no-repeat;
}

.sort-arrow-down.deactive {
    background: url("./../../../assets/images/down_unselected.png") no-repeat;
}
