.notificationStyle .notification {
    font-size: 16px !important;
    font-weight: 600;
    min-height: 60px;
    right: 24px !important;
    padding: 15px 18px !important;
    letter-spacing: 0.6px;
}

.notificationStyle .notification-dismiss {
    font-size: 24px !important;
    right: 16px !important;
    top: 10px !important;

}