.graph-wrapper {
    height: 250px;
}

.report-list .table-style {
    min-height: 200px;
}

.report-list .dropdown button {
    width: 160px;
    text-align: left;
}

.report-list .dropdown button .caret {
    float: right;
    /* margin-top: 9px; */
}

.report-list .dropdown {
    margin-left: 10px;
}

/* .wrapper-gps .select-dropdown .Select-arrow-zone .Select-arrow {
    border-width: 7px 6px 1.5px;
} */

.wrapper-gps .select-dropdown {
    min-width: 160px;
    margin-right: 5px;
}
/* 
.wrapper-gps .Select-control {
    background-color: transparent;
    border-radius: 0;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, .3);
} */

.wrapper-gps .select-dropdown .Select-placeholder, .select-dropdown .Select--single > .Select-control .Select-value .Select-value-label {
    font-size: 15px;
    font-weight: 600;
}

.Select.is-focused > .Select-control, .Select.is-focused:not(.is-open) > .Select-control {
    background-color: transparent;
    border-color: #999;
}

.wrapper-gps .Select.is-open > .Select-control .Select-arrow {
    border-width: 0 5px 5px;
}

.wrapper-gps .select-dropdown .Select-input {
    height: 28px;
}

.wrapper-gps .select-dropdown .Select--single > .Select-control .Select-value {
    line-height: 28px;
}

.report-section .export-container {
    height: 30px;
}

.report-list .table-style .table > tbody > tr > td {
    padding: 12px 8px;
}

.report-list .table-style .table > .normal_table_head > tr > th {
    padding: 12px 8px;
}