.button-container {
    display: inline-flex;
    float: right;
    background-color: #F47C21;
    height: 32px;
    padding: 0px 10px;
    align-items: center;
    position: relative;
    margin-right: 10px;
    border-radius: 2px;
}

.button-container:hover {
    cursor: pointer;
}


.button-container a {
    text-decoration: none;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: white;
    font-weight: 400;

}

.button-container a span {
    vertical-align: middle;
}

.button-container .icon {
    width: 25px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0px;
}
@media (max-width: 375px) {
    .button-container .icon + span {
        display: none;
    }
}