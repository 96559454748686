.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li {
    border-left: 4px solid transparent;
    width: 200px;
}

.sidebar ul li a i {
    margin-right: 10px;
}

#wrapper .sidebar ul li:hover a, #wrapper .sidebar ul li.active a {
    color: #fff;
    background: #100d19;
    border-left-color: #F47C21;

}

.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0 !important;
}

.sidebar .nav-second-level li a {
    padding-left: 37px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

#wrapper .sidebar ul li a > span {
    color: #a6abad;
}

#wrapper .sidebar ul li a.active-link > span {
    color: #F47C21;
    font-weight: 600;
}

@media (min-width: 768px) {
    .sidebar {
        z-index: 1;
    }

    .nav-toggle .sidebar ul li {
        width: 60px;
    }
    .nav-toggle .sidebar ul li:hover {
        width: 200px;
    }

}

.sidebar ul li a .icon-size {
    font-size: 22px;
}

.sidebar ul li a .home-size {
    font-size: 19px;
}

/*sidebar-icons*/

.icon-container {
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    vertical-align: middle;
}

.sidebar-icon {
    width: 22px;
    height: 22px;
    display: inline-block;
}

.home-icon {
    background: url("../../../assets/images/home_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .home-icon {
    background: url("../../../assets/images/home_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.device-icon {
    background: url("../../../assets/images/device_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .device-icon {
    background: url("../../../assets/images/device_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.alerts-icon {
    background: url("../../../assets/images/unselected_alert.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .alerts-icon {
    background: url("../../../assets/images/alerts_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.region-icon {
    background: url("../../../assets/images/region_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .region-icon {
    background: url("../../../assets/images/region_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.protocol-icon {
    background: url("../../../assets/images/protocol_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .protocol-icon {
    background: url("../../../assets/images/protocol_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.vehicle-icon {
    background: url("../../../assets/images/vehicle_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .vehicle-icon {
    background: url("../../../assets/images/vehicle_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.cron-icon {
    background: url("../../../assets/images/cron_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .cron-icon {
    background: url("../../../assets/images/cron_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.bsd-icon {
    background: url("../../../assets/images/bsd_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .bsd-icon {
    background: url("../../../assets/images/bsd_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.trip-icon {
    background: url("../../../assets/images/trips_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .trip-icon {
    background: url("../../../assets/images/trips_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.master-icon {
    background: url("../../../assets/images/master_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .master-icon {
    background: url("../../../assets/images/master_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.user-icon {
    background: url("../../../assets/images/user_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .user-icon {
    background: url("../../../assets/images/user_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.map-icon {
    background: url("../../../assets/images/map_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .map-icon {
    background: url("../../../assets/images/map_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}
.report-icon {
    background: url("../../../assets/images/report_menu_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.active-link .report-icon {
    background: url("../../../assets/images/report_menu_selected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.logout-icon {
    background: url("../../../assets/images/logout_unselected.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 22px auto;
}

.delete-icon {
    background: url("../../../assets/images/close.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 19px auto;
    width: 19px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer
}

.nav {
    float: left;
    margin-top: 6px;
}

#wrapper .nav > li > a {
    color: #c9c9c9;
    padding: 12px 5px 12px 18px;
    text-decoration: none;
    border-left: 4px solid transparent;

}

#wrapper .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background-color: transparent;
    color: #100d19;
}

#wrapper .nav > li > a.dropdown-toggle:hover {
    background-color: transparent;
    color: #100d19;
}

#wrapper .nav > li > a.dropdown-toggle {
    color: #100d19;
    padding: 25px 5px 24px 18px;
}

.nav > li > a:hover, .nav > li > a.active {
    color: #ffffff;
}

.nav > li > a:focus {
    background-color: transparent;
    outline: none;
}

#wrapper .navbar-default {
    background-color: transparent;
    border: none;
}

/*#wrapper .navbar-default .navbar-brand {*/
/*!*background-color: #100d19;*!*/
/*color: #ffffff;*/
/*width: 250px;*/
/*!* height: 80px; *!*/
/*!*box-shadow: 0px 8px #100d19;*!*/
/*padding: 20px 27px;*/
/*transition: all 0.4s;*/
/*white-space: nowrap;*/
/*position: absolute;*/
/*left: 0;*/
/*top: 0;*/
/*}*/

/*#wrapper .navbar-default .navbar-brand span {*/
/*vertical-align: middle;*/
/*white-space: nowrap;*/
/*letter-spacing: 1.5px;*/
/*font-size: 26px;*/
/*line-height: 29px;*/
/*}*/

.sub-menu-container {
    color: #a6abad;
    padding: 12px 18px 12px;
    border-left: 4px solid transparent;
    /* width: 10rem; */
}
.sub-menu-container:hover {

    padding-right:0px;
}


.sub-menu-container:hover {
    background: #100d19;
}


.sub-menu-container .sub-menu a {
    height: auto;
    width: 8rem;
    padding-top:12px;
    display: block;
    transition: all 0.7s;
}

/* .sub-menu-container .sub-menu a {
   height: 0;
    width: 0;
    display: none;
    text-decoration: none;

} */

.nav-toggle .sub-menu-container > span {
    display:none;
}
.nav-toggle .sub-menu-container:hover span {
    display: inline-block;
}
.sub-menu{
    margin-left: 30px;
}
.sub-menu-container .active-link +span{
    color:#f47c21;
    font-weight: 600;
}
#wrapper .sidebar ul li:hover .sub-menu-container {
    border-left-color: #F47C21;

}

.navlink-arrow{
    position: absolute;
    right: 10%;
    cursor: pointer;
    font-weight: 18px;
 }
 ::-webkit-scrollbar {
    width: 0px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}