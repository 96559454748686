.table-style .table > tbody > tr > td {
    padding: 14px 8px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
}
.table-style .table > thead > tr > th {
    vertical-align: middle;
    outline: none;
    padding: 14px 8px;
    border-bottom: 1px solid #ddd;
}
.table-style {
    min-height: 480px;
    padding-bottom: 25px;
}

.table-style .table > tbody > tr.noDataStyle td {
    padding: 50px;
    text-align: center;
    font-weight: 500;
}

.table-style .table > tbody > tr > td {
    padding: 10px 8px;
}
.table-style .table > .normal_table_head > tr > th {
    padding: 8px 2px;
}

.table-style .table > thead > tr > th {
    padding: 8px 8px;
}
.table-style .table > .normal_table_head > tr > .test-header {
    padding-top: 50px;
}
.table-hover > tbody > tr.noDataStyle:hover {
    background-color: #fff;

}

table.dataTable {
    margin-bottom: 0px;
}

table.dataTable thead tr th {

}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}

@media screen and (max-width: 767px) {
.table-style {
    min-height: 0;
}
    .table-responsive {
        border: none;
    }
}