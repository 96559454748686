#wrapper .logo {
    padding: 12px 27px;
    white-space: nowrap;
}
.small-nav-handle .fa-bars {
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.small-nav-handle {
    padding: 0 20px 0 0px;
    display: inline-block !important;
}
@media (min-width: 768px) {
    .nav-toggle .logo-icon, .nav-toggle .crut-logo-icon {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .small-nav-handle {
        position: fixed;
        top: 12px;
        right: 15px;
        z-index: 99;
        padding: 0;
    }

    .small-nav-handle .fa-bars {
        color: #fff;
    }
    .small-nav-handle .fa-bars:before {
        content: "\f00d";
        content: "x";
        font-family: monospace;
        font-size: 30px;
        line-height: 1;
        position: relative;
        top: -4px;
    }

    .nav-toggle .small-nav-handle .fa-bars:before {
        content: "\f0c9";
        font-family: FontAwesome;
    }
}
.menu-icon {
    margin-right: -15px;
    vertical-align: middle;
    cursor: pointer;
}


.logo-icon {
    background: url("../../assets/images/chalo_logo_white.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100px auto;
    width: 100px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.crut-logo-icon {
    background: url("../../assets/images/crut_logo.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100px auto;
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    background-color: #fff;
    border-radius: 15rem;
}
@media screen and (max-width: 767px) {

    .menu-icon {
        background: url("../../assets/images/close_cross.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 24px auto;
    }

    .nav-toggle .menu-icon {
        background: url("../../assets/images/menu.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 24px auto;
    }
}
@media (min-width: 768px) {
    .menu-icon {
        background: url("../../assets/images/menu.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 24px auto;
    }}




#wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #100d19;
    -webkit-transition: width .7s; /* For Safari 3.1 to 6.0 */
    transition: width .7s;
    width: 200px;

}

/*responsive css*/
@media screen and (max-width: 767px) {


    #wrapper .navbar-default {
        background: #100d19;
    }

    .collapse {
        display: block;
        position: fixed;
        background: #100d19;
        top: 58px;
        bottom: 0;
        left: 0px;
        max-height: calc(100vh - 60px);
        overflow-y: auto;
        transition: all .7s ease;
        -webkit-transition: all .7s ease;
        -moz-transition: all .7s ease;
    }

    .nav-toggle .collapse {
        display: block;
        left: -255px;
    }



    #wrapper {
        z-index: 9;
        width: 100%;
        padding: 3px;
    }



}
@media (min-width: 768px) {
    #wrapper {
        bottom: 0;
    }
    .nav-toggle div#wrapper {
        width: 75px;
        transition: all .7s;
    }

    .nav-toggle #wrapper .nav > li > a > span:not(.caret) {
        display: none;
    }

    .nav-toggle #wrapper .nav > li:hover span:not(.caret) {
        display: inline-block;
    }
}