.language-list{
    margin-top: 1rem;
}

.language-btn{
    margin-right:10px;
    background-color:#f47c21;
    color:#fff;
    border:none;
    padding:4px 10px;
}

.language-scroll-container{
    max-height: 550px;
    overflow: scroll;
  }
  
.language-scroll-container::-webkit-scrollbar {
    width: 10px!important;
}

.language-scroll-container::-webkit-scrollbar-thumb{
    border-radius: 0px;
    background-color: #F47C21;
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}