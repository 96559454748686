
.button-container .export-icon {
    background: url("./../../../assets/images/export_white.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 19px auto;
}



