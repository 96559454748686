.configuration-view {
    display: inline-block;
    height: 20px;
    width: 100px;
    position: relative;
}

.configuration-view .dropdown {
    position: absolute !important;
}

.configuration-view .dropdown .dropdown-menu {
    left: -77%;
}

.configuration-view .dropdown #dropdown-size-medium {
    padding: 2px 5px;
}

td.action-column {
    overflow: visible !important;
}
.configuration-modal .table-style .table > tbody > tr > td:nth-child(n+6) {
    overflow: visible !important;
}


.configuration-modal .title-view .modal-header {
    padding: 0;
}

.configuration-modal .title-view .modal-header .close {
    margin-top: 12px;
    margin-right: 12px;
}

.configuration-modal .dropdown {
    width: 100%;
}

.configuration-modal .dropdown button .caret {
    float: right;
    border-top: 7px dashed;
    border-top: 4px solid \9;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    margin-top: 10px;
}

.configuration-modal .dropdown-menu {
    width: 100%;
    border-radius: 0px;
}

.configuration-modal .btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.configuration-modal .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
    background-color: transparent;
}

.configuration-modal .dropdown button {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    text-align: left;

}

.configuration-modal .setting-item .label {
    text-align: left;
    color: #100d19;
    font-weight: 600;
    line-height: 2;
    font-size: 100%;
}

.configuration-modal .setting-item {
    margin-bottom: 15px;
}

.configuration-modal .setting-item .region-selector-wrapper {
    width: 100%;
}

.configuration-modal .setting-item .select-dropdown {
    width: 100%;
}

.configuration-modal .setting-item .select-dropdown .Select-control {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    box-shadow: none;
}

.configuration-modal .setting-item .select-dropdown .Select-arrow-zone .Select-arrow {
    border-top: 7px dashed;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
}

.configuration-modal .modal-body {
    padding: 35px;
    padding-bottom: 0px;
}

.configuration-modal .modal-footer button {
    color: white;
    width: 100px;
    font-size: 16px;
    border-radius: 0;
}

.configuration-modal .modal-footer button.error-button {
    background: #100d19;
}

.configuration-modal .modal-footer button.success-button {
    background: #f47c21;
    border: none;
}

.configuration-modal .modal-footer {
    padding: 20px 35px 35px;
    border-top: none;
}

.reconfigure-icon {
    width: 28px;
    cursor: pointer;
}

.input-item {
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    padding: 0 10px;
}

.input-item:focus {
    outline: none;
}

.configuration-modal-wrapper {
    margin-right: 10px;
}