.confirmation-modal button,
.confirmation-modal button:hover {
    margin-left: 20px;
    padding-left: 25px;
    padding-right: 25px;
}

.modal-footer .confirmation-modal .btn + .btn {
    margin-left: 20px;
    background-color: #f47c21;
    color: white;
}

.confirmation-modal button {
    padding-left: 25px;
    padding-right: 25px;
}

.modal-wrapper {
    display: inline-block;
}

.header-data {
    margin-left: 25px;
}

.header-data .value{
    font-weight: 600;
}
