.create-language-with-city .table-heading{
    display: flex;
    justify-content: space-between;
}

.table-header{
    background-color: #F47C21;
    color: #fff;
    padding: 0 5px;
}

.table-heading span{
    font-weight: bold;
}

.table-row {
  text-align: left;
  padding: 2px;
  border-bottom: 1px solid #ddd;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.scroll-container{
    margin-top: 2px;
    max-height: 300px;
    overflow: scroll;
  }
  
.scroll-container::-webkit-scrollbar {
width: 10px!important;
}

.scroll-container::-webkit-scrollbar-thumb{
border-radius: 0px;
background-color: #F47C21;
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}


.custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #F47C21;
  }
  .custom-toggle.react-toggle--checked .react-toggle-thumb{
    border-color: #F47C21;
    box-shadow: none;
    left: 18px;
  }

  .custom-toggle.react-toggle--focus .react-toggle-thumb{
    box-shadow: none;
  }

  .custom-toggle.react-toggle--checked:hover .react-toggle-track {
    background-color: #F47C21;
  }
  .custom-toggle.react-toggle:hover:not(.react-toggle--checked) .react-toggle-track {
    background-color: #4D4D4D;
  }
  .custom-toggle.react-toggle .react-toggle-thumb{
      width: 20px;
      height: 20px;
      top:-3px;
  }
  .custom-toggle.react-toggle .react-toggle-track{
      width: 36px;
      height: 14px;
  }

  .custom-toggle.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb{
      box-shadow: none;
  }
  
  
.language-drop-down-height{
    height: 110px;
    overflow: scroll;
}

.language-drop-down-height::-webkit-scrollbar {
    width: 10px!important;
    }
    
.language-drop-down-height::-webkit-scrollbar-thumb{
border-radius: 0px;
background-color: #F47C21;
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.action-button{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.edit-language{
  margin-top: -25px;
  margin-left: -15px;
}

.delete-btn-container{
  display: flex;
  justify-content: space-between;
}

.delete-btn-container div{
  font-weight: bold;
}

.delete-btn{
  background-color: #F47C21;
  border: none;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 1rem;
}

.selected-language-name{
  width: 100px;
}
.delete-icon-container{
  padding-right: 10px;
}

.delete-icon-container-empty{
  padding-right: 10px;
  width: 14px;
}