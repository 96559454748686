.dropDown-wrapper{
    display: inline-flex;
    float: right;
    /* background-color: #F47C21; */
    height: 32px;
    padding: 0px 10px;
    align-items: center;
    /* position: relative; */
    margin-right: 10px;
    border-radius: 2px;

}
.dropDown-wrapper>.open>.dropdown-menu{
    right: 0px;
    left: auto;
}
.dropDown-wrapper>.dropdown >.dropdown-menu>li>a{
    padding: 5px 20px;
}
.dropDown-wrapper>.dropdown>.dropdown-button{
    background-color: #F47C21;
    color: white;
    border: none;
}
.dropDown-wrapper>.dropdown>.dropdown-button>.caret{
    margin-left: 6px;
}

.dropDown-wrapper>.open>.dropdown-toggle.btn-default:hover{
    background-color: #F47C21;
    color: white;
    box-shadow: none;
}
.dropDown-wrapper>.open>.dropdown-toggle.btn-default{
    background-color: #F47C21;
    color: white;
    box-shadow: none;
}