
.menu-item {
    text-transform: capitalize;
    padding: 5px 20px;
}

.menu-container {
    position: relative;

}

.menu-container .sub-menu {
    display: none;
    position: absolute;
    left: 140px;
    top: 10px;
    padding: 5px 0px;
    color: black;
    z-index: 1000;
    min-width: 160px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    border-top-left-radius: 0px;
}

.menu-container .sub-menu li {
    padding: 5px 20px;
}

.menu-container .sub-menu li:hover {
    background: #F47C21;
    color: #fff;
}

.menu-container:hover .sub-menu {
    display: block;

}