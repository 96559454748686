.healthcheck-table-style .table > tbody > tr > td {
    padding: 14px 8px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
}

.healthcheck-table-style .table > thead > tr > th {
    vertical-align: middle;
    outline: none;
    padding: 14px 8px;
    border-bottom: 1px solid #ddd;
}

.error-count {
    float: right;
    margin-right: 25px;
}
