.page-header {
    border-bottom: none;
    margin: 0;
    padding: 10px 0px;
}

.page-header .delayDiv {
    position: static;
    margin-top: 10px;
}

.page-header h3 {
    display: inline-block;
    margin: 0;
    padding-right: 6px;
    vertical-align: middle;
}

#wrapper .page-header {
    margin: 20px 0 0;
}

@media (min-width: 768px) {
    .page-header .add-button span .fa {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .page-header:after, .page-header::before {
        content: "";
        display: table;
        width: 100%;
    }

    .page-header .cluster-ip {
        padding-left: 0;
        display: inline-block;
        vertical-align: middle;
    }

    .page-header h3 {
        padding-right: 25px;
    }
}

@media (max-width: 375px) {
    .page-header h3 {
        padding-right: 0px;
    }
}

@media (max-width: 475px) {
    .page-header .cluster-ip {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 520px) {
    .page-header .delayDiv {
        width: 100%;
        margin: 0;
    }

    .page-header .button-text {
        display: none;
    }

    .page-header .add-button span .fa {
        display: inline-block;
    }

    .page-header .button-text + span {
        padding-left: 0px;
    }

    .page-header {
        padding-right: 0;
    }
}

.clear-float {
    clear: both;
}

.page-header .button-container:first-child {
    margin-right: 0;
}