/*map-info-window*/
.info-window {
    width: 220px;
    font-size: 14px;
    font-weight: 400;
}

.info-window .time {
    color: #7c8183;
}

.info-window .address-view {
    margin-top: 5px;
}