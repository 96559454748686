.regions .search-container{
    margin-right: 15px;
}



.custom-chip-container{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;
    border:1px solid black;
    padding: 5px;
    border-radius: 5px;
}

.custom-chip-container i {
    padding-left: 3px;
}

.custom-chip{
    border: none;
    flex: 1;
    outline: none;
}

.custom-chip:focus{
    border: none;
}