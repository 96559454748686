.gm-style button[title="Toggle fullscreen view"] {
    display: none;
}

.gm-style-pbc {
    display: none;
}
.map-wrapper-component {
    position: relative;
}

.map-wrapper .re-center-button {
    top: 0;
    right: 10px;
}
/*re-center-button*/

.re-center-button {
    display: inline-block;
    border: 0;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    float: right;
    vertical-align: middle;
    position: absolute;
    z-index: 8;
}

.re-center-button.detail-view {
    position: absolute;
    top: 40px;
    right: 26px;
    z-index: 999999999;
}

.re-center-button.detail-view .span-icon {
    margin-top: 0;
    margin-left: 0;
}


.re-center-button.re-locate {
    top: 40px;
}

.span-icon.recenter-icon {
    background: url("../../../assets/images/recenter.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 40px auto;
    width: 40px;
    height: 40px;
    margin-top: 14px;
}
.span-icon.current-icon {
    background: url("../../../assets/images/my_location.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 40px auto;
    width: 40px;
    height: 40px;
    margin-top: 8px;
}