.page-wrapper .pager > li > a {
    color: #100d19;
    border-color: #100d19;
    padding: 3px 14px;
    border-radius: 2px;
    float: none

}

.page-wrapper .pager > .active > a,
.page-wrapper .pager > .active > a:focus,
.page-wrapper .pager > .active > a:hover,
.page-wrapper .pager > .active > span,
.page-wrapper .pager > .active > span:focus,
.page-wrapper .pager > .active > span:hover, .pager li > a:focus, .pager li > a:hover {
    color: #100d19;
    border-color: #100d19;
    outline: none;
}

.pager {
    margin: 0;
}

.pager li {
    display: inline-block;
}

.pager li > span {
    padding: 3px 13px;
}

.page-wrapper .pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
    color: #a0a09f;
    border-color: #a0a09f;
    background-color: #fff;
    pointer-events: none;
}

.pagination-wrap {
    display: inline-block;
}

.pagination-wrap .resetBtn {
    padding-bottom: 3px;
}

.goToBtn .btn {
    color: #100d19;
    padding: 6px 8px;
    height: 34px;
    font-size: 12px;
}
@media (max-width: 520px) {

    .cusPagination .pager .pagination-wrap {
        margin: 15px auto;
    }

    .cusPagination .pager li a {
        float: none;
        margin: 0;
        font-size: 14px;
    }
}

.cusPagination .pager .form-group {
    max-width: 140px;
    display: inline-block;
    margin: 0 15px;
}
.cusPagination .pager li a {
    float: none;
}