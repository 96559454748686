/* select dropdown css*/
.select-dropdown {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    float: left;
}
.select-dropdown-country{
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    float: left;
}
.select-dropdown .Select-control {
    height: 32px;
    border-radius: 2px;
}

.select-dropdown .Select-input {
    height: 30px;
    padding-left: 14px;
}

.select-dropdown .Select-arrow-zone {
    display: table-cell;
}

.select-dropdown .Select-arrow-zone .Select-arrow {
    border-color: #333 transparent transparent;
    border-width: 4px 4px 2.5px;
}

.select-dropdown .Select-multi-value-wrapper {
    padding-left: 0;
}

.select-dropdown .Select-multi-value-wrapper::before {
    display: none;
}

.select-dropdown .Select-placeholder, .select-dropdown .Select--single > .Select-control .Select-value {
    line-height: 30px;
    margin-left: 0;
    text-transform: capitalize;
}

.select-dropdown .Select-placeholder, .select-dropdown .Select--single > .Select-control .Select-value .Select-value-label {
    text-transform: capitalize;
    font-size: 14px;
}

.select-dropdown .Select-menu-outer, .select-dropdown .Select--single > .Select-control .Select-input input {
    text-transform: capitalize;
    font-size: 14px;
}

.select-dropdown .Select-loading-zone {
    padding-right: 8px;
    padding-bottom: 2px;
}

.select-dropdown .Select-menu-outer .Select-noresults {
    display: none;
}

.select-dropdown .is-loading .Select-menu-outer {
    border: none;
}

.select-dropdown .Select-option.is-focused {
    background-color: #F47C21;
    color: white;
}

.select-dropdown .Select.is-open > .Select-control {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.select-dropdown .Select-option :hover {
    background-color: #F47C21;
    color: white;
}

@media (max-width: 425px) {
    .select-dropdown {
        float: left !important;
        padding-left: 0px;
    }
}