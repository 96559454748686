.search-bar {
    position: relative;
    display: inline-block;
}

.search-bar .search-btn {
    width: 22px;
    height: 22px;
    position: absolute;
    padding: 0;
    border: 0 none;
    left: 4px;
    top: 7px;
    background: transparent url("./../../../assets/images/search.png") no-repeat;
    background-size: 20px auto;
}

.search-bar .clear-btn {
    width: 22px;
    height: 17px;
    position: absolute;
    right: 0px;
    top: 7px;
    background: transparent url("./../../../assets/images/close.png") no-repeat;
    padding: 0;
    border: 0 none;
    outline: 0px;
    box-shadow: none;
    background-size: 17px auto;
}

.search-bar .btn-default:active:focus {
    background-color: white;
}

.search-bar input[type="text"] {
    height: 32px;
    color: #100d19;
    border: solid 1px #ddd;
    padding-left: 30px;
    border-radius: 2px;
    box-shadow: none;
    background: transparent;
}

.search-bar span {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.search-bar .form-group {
    margin-bottom: 0;
}

@media (max-width: 425px) {
    .search-container {
        float: left !important;
    }
}

@media (max-width: 832px) {
    .device-page .search-container {
        float: left !important;
    }

    .device-page .form-inline .form-group {
        margin-bottom: 15px;
    }
}
