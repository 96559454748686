.editableTable {
    width: 95%;
    border-collapse: collapse;
  }

  .editableTable thead {
    background-color: #F47C21;
    color: white;
  }

  .editableTable th,
  .editableTable td {
    text-align: left;
    padding: 2px;
    border-bottom: 1px solid #ddd;
  }

  .editableTable tbody tr:hover {
    background-color: #f5f5f5;
  }

  .editableTable button {
    background-color: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .editableTable button:hover {
    transform: scale(1.1);
  }

  .editableTable input[type="text"] {
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 80px); /* Adjust width to account for padding and border */
  }

.add-agency-row{
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
}

.add-agency-row button{
  background-color: #F47C21;
  color: #fff;
  border: none;
  margin-bottom: 1rem;
  padding: 2px 5px;
  border-radius: 2px;
}

.scroll-container{
  max-height: 450px;
  overflow: scroll;
}

.scroll-container::-webkit-scrollbar {
  width: 10px!important;
}

.scroll-container::-webkit-scrollbar-thumb{
  border-radius: 0px;
  background-color: #F47C21;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}