body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.btn-toolbar{
  display: flex;
}
.detail{
  line-height: 32px;
}
@media (max-width: 768px) {
  .btn-toolbar{
    flex-wrap: wrap;
  }
}