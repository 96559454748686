.device-config-row{
    padding-left: 0.5rem;
    display: flex;
}
.device-config-col-1{
    padding-right: 1rem;
    width: 6rem;
}
.device-config-col-2{
    width: 150px;
}

.flex{
    display: flex;
}

.command-container span{
    padding: 0 5px;
}

.command-preview{
  font-weight: bolder;
  padding-left: 2rem;
  color: #F47C21;

}


.editable-table-1 {
    width: 95%;
    border-collapse: collapse;
  }

  .editable-table-1 thead {
    background-color: #F47C21;
    color: white;
  }

  .editable-table-1 th,
  .editable-table-1 td {
    text-align: left;
    padding: 2px;
    border-bottom: 1px solid #ddd;
    word-break: break-all;
  }

  .editable-table-1 td {
    padding: 0 10px!important;
  }

  .editable-table-1 tbody tr {
    background-color: #f5f5f5;
  }

  .editable-table-1 button {
    background-color: transparent;
    border: none;
    padding: 3px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .editable-table-1 button:hover {
    transform: scale(1.1);
  }

  .editable-table-1 input[type="text"] {
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 80px); 
  }



.scroll-container-1{
  padding-left:2rem;
  max-height: 12rem;
  overflow: scroll;
}

.scroll-container-1::-webkit-scrollbar {
  width: 10px!important;
}

.scroll-container-1::-webkit-scrollbar-thumb{
  border-radius: 0px;
  background-color: #F47C21;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.cmd-scroll{
  padding-left: 0px;
  margin-top: 2px;
  max-height: 12rem;
  overflow: scroll;
}
.cmd-scroll .table-heading{
  padding:0px 20px;
}
.region-label{
  padding-right: 1.1rem;
}
.device-label{
  padding-right: 0.3rem;
}

.preview-loading{
 text-align: center;
}

.preview-loading i{
  color: #F47C21;
  font-weight: bolder;
  font-size: 3rem;
}
.command-hypen{
  word-break: break-all;
}

.device-configuration{
  padding-right: 1rem!important;
}

textarea{
  height: 120px!important;
}
.command-create-container{
  margin-top: 0.3rem;
}

.custom-preview-button{
  background-color: #337ab7!important;
  border-color: #2e6da4!important;
}
.pr-3{
  padding-right:1.688rem!important;
}

.command-row{
  display: flex;
  margin-left: -12px!important;
  font-size: 14px;
}

.command-col-1{
  font-weight: bolder;
  width: 10%;
}

.command-col-2{
  width: 90%;
  word-break: break-word;
}


.command-response-row{
  display: flex;
  margin-left: -12px!important;
  font-size: 14px;
}

.command-response-col-1{
  font-weight: bolder;
  width: 19%;
}

.command-response-col-2{
  width: 81%;
  word-break: break-word;
}
.selected-command-name{
  padding-right:40px ;
}
.create-command-table .table-heading{
  display: flex;
  justify-content: space-between;
  padding:0 10px;
}

