
html {
    color: #100d19;
    font-size: 1em; /* ~16px; */
    line-height: 1.375; /* ~22px */
    height: 100%;
}

body {
    font-family: 'PT Sans', sans-serif;
    background-color: #eee;
    height: 100%;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: #100d19;
}

a {
    color: #0074c2;
    cursor: pointer;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

:root {
    --brand-color: #61dafb;
}

#root {
    height: 100%;
}

#root > div {
    height: 100%;
}

.container {
    margin: 0 auto;
    padding: 20px 0;
    max-width: var(--max-content-width);
}

/*
 * Browser upgrade prompt
 * ========================================================================== */

:global(.browserupgrade) {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.action-div {
    display: inline-block;
    padding-left: 25px;
}

.action-column > span {
    margin-right: 10px;
}

.action-column span a {
    color: #100d19;
}

.filter-wrapper {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.dataTables_filter {
    float: right;
}

/*logo css*/

@media print {
    *,
    *::before,
    *::after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]::after {
        content: ' (' attr(href) ')';
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    a[href^='#']::after,
    a[href^='javascript:']::after {
        content: '';
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

.toggled {
    width: 75px;
}

.filters-label {
    display: inline-block;
    vertical-align: bottom;
    padding-right: 12px;
    font-size: 16px;
    margin: 0 0 5px;
}

.dataTables_length {
    display: inline-block;
    padding-right: 14px;
    vertical-align: middle;
}
.dropdown .btn .caret {
    float: right;
    position: relative;
    top: 8px;
}

.dropdown-menu {
    z-index: 8;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .dropdown-menu > li > a.selected {
    color: #fff;
    text-decoration: none;
    background-color: #F47C21;
}

.dropdown .dropdown-menu > li > a {
    padding: 0px;
}

.dropdown #dropdown-size-medium {
    text-transform: capitalize;
    min-width: 150px;
    text-align: left;
}

.dataTables_filter .dropdown-menu {
    right: 0;
}

.resetBtn {
    vertical-align: top;
    color: #100d19;
    padding: 5px;
}

.btn, .btn:focus,
.btn.active:focus,
.btn.focus,
.btn:active:focus,
.btn:focus,
.btn:active {
    outline: none;
}

.btn-link:hover {
    text-decoration: none;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover {

    background-color: #F47C21;;
    border-color: #F47C21;;
    outline: none;
    padding: 5px 12px;
    border-radius: 2px;
}

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
    background-color: transparent;
    border-color: transparent;
}

.btn-default {
    border-color: #ddd;
    background-color: transparent;
    padding: 5px 12px;
    border-radius: 2px;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.active-region {
    color: #689f38;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee !important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}

.navbar-nav {
    float: right;
}

.stat .panel-body {
    display: none;
}

/* header */

/* confirm delete box style */
.close {
    opacity: 1;
    color: #100d19;
    font-size: 24px;
}

.confirm-box .modal-body {
    padding: 30px 16px;
}

.confirm-box .modal-body p {
    margin: 0;
}

.confirm-box .modal-dialog {
    width: 375px;
}

.protocol {
    cursor: pointer;
}

.protocol-list {
    display: none;
    position: absolute;
    background: white;
    white-space: pre-wrap;
    text-align: left;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    padding: 5px 5px;
    margin: 2px 0 0;
}

.protocol:hover + .protocol-list {
    display: block;
}

.banner {
    text-align: center;
}

.bannerTitle {
    margin: 0;
    padding: 10px;
    font-weight: normal;
    font-size: 4em;
    line-height: 1em;
}

.bannerDesc {
    padding: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.25em;
    margin: 0;
}

.marginTp {
    margin-top: 15px;
}

@media (min-width: 768px) {
    /*refresh modal*/
    .custom-modal {
        width: 350px;
    }

    .form-horizontal .control-label {
        font-size: 15px;
        padding: 5px 0px;
    }

    .info-modal {
        width: 800px;
    }

    span.goToBtn.input-group input.form-control {
        width: 85px;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
    .info-text{
        font-size: 13px;
        color: #00529B;;
    }
    .error-text{
        font-size: 12px;
        color: red;
    }
    .custom-operator-modal{
        width: 725px;
    }
}

.deviceModalValueLabel {
    display: inline-block;
    max-width: 100%;
    font-weight: 300;
    margin-bottom: 0;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
}

.form-horizontal .control-label.deviceModalValueLabel {
    text-align: left;
    padding-left: 20px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

    ul.timeline > li > .timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }

    ul.timeline > li > .timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }

    /*user-create-view changes*/
    .mobile-view-value {
        display: inline-block;
    }

    .mobile-view-delete {
        position: absolute;
        top: 6px;
        right: 10px;
    }

    .custom-dropdown-inline {
        margin-top: 8px;
    }

    /* device-detail-map-view*/
    .map-wrapper-component {
        margin-top: 15px;
    }
    .info-text{
        font-size: 12px;
        color: #00529B;;
    }
    .error-text{
        font-size: 12px;
        color: red;
    }
}

.header-button-container {
    display: inline-block;
}

.modal-body {
    /*padding: 15px 15px 0px;*/
}

.form-control:focus {
    box-shadow: none;
}

.form-label {
    font-weight: bold;
}

.form-label > span:after {
    content: '*';
    position: absolute;
    color: #f00;
    bottom: 5px;
    font-size: 15px;
}

.detail-container {
    padding: 15px;
}

.detail-container input {
    border: none;
    border-bottom: solid 1px #979797;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
}

.file-upload {
    margin-bottom: 5px;
    position: relative;
}

.file-select-footer {
    text-align: right;
}

/*
time select options
*/
.time-dropdown + .dropdown-menu {
    min-width: 65px;
}

.red-class a {
    color: red;
}

.yellow-class a {
    color: yellow;
}

.green-class a {
    color: green;
}

.class-pink a{
    color:pink
}

.class-blue a{
    color:blue
}

.class-green a{
    color:green
}

.class-yellow a{
    color:yellow
}

.class-red a{
    color:red
}

.class-orange a{
    color:orange
}

.class-purple a{
    color:purple
}

.list-item {
    text-transform: capitalize;

}

.list-item-red{
    text-transform: capitalize;
    color:red;
    font-weight: bold;
}

.list-item-yellow{
    text-transform: capitalize;
    color:yellow;
    font-weight: bold;
}

.list-item-green{
    text-transform: capitalize;
    color:green;
    font-weight: bold;
}

.highlight {
    background-color: #FFE500;
}

.cluster-ip {
    padding-left: 25px;
    vertical-align: middle;
}

/* .icon {
    background: url("./../assets/images/sidebar-sprite.png") no-repeat left 4px;
    background-size: 70px auto;
    width: 20px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
} */

.in-middle {
    vertical-align: sub;
}

.setting-item + .setting-item {
    margin-top: 15px;
}

.form-view button {
    float: right;
    margin: 10px;
}

.home-header {
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
    position: fixed;
    width: 100%;
    z-index: 999;
}

.home-header .header-container {
    padding: 10px 30px;

}

.home-header .dashboard-logo img {
    height: 40px;
}

.home-header span {
    margin-left: 7px;
    font-size: 20px;
    font-weight: 600;
    color: #ba2908;
}

.home-header .right-side-icons {
    text-align: right;
}

.form-group .radio-view {
    margin-left: 10px;
}

.label-view {
    margin-right: 25px;
}

.label-link {
    color: #5cb85c;
}

.add-role {
    text-align: center;
}

.center-link {
    color: #fff;
    background-color: #5cb85c;
    border-color: #3d8b3d;
    outline: none;
}

.form-group .center-block {
    text-align: center;
}

.delegated-div {
    display: inline;
}

.hide-delegated-div {
    display: none;
}

.add-role-disabled {
    color: #b5d7b5
}

.roles-view {
    list-style: none;
    background: #eee;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;

}

.roles-view select {
    background: transparent;
    padding: 3px;
    border-radius: 2px;
    width: 100%;
}

.roles-view li {
    margin-top: 15px;
    background: white;
}

.roles-view li:first-child {
    margin-top: 0;
}

.roles-view li:last-child {
    background: #eee;
}

.roles-view .control-label {
    text-align: right;
}

.cus-table th {
    white-space: nowrap;
}

.header-button-container .form-group {
    display: inline-block;
}

/*select css*/
.select-lable {
    display: inline-block;
}

.select-input {
    display: inline-block;
    padding-left: 14px;
    vertical-align: middle;
    width: 257px;
}

.fa-chevron-up {
    padding-right: 3px;
    font-size: 17px;
}

/*table-text-center-align*/
.center-align {
    text-align: center;
}

/*device-detail-modal*/
.info-modal .modal-content {
    border-radius: 0;
}

.info-modal .modal-content .modal-header {
    padding: 10px 10px 15px 30px;
}

.device-info {
    padding: 0px 0px 15px 30px;
}

.vehicle-info {
    padding: 0px 0px 15px 15px;
}

.info-value {
    display: inline-block;
    max-width: 100%;
    font-weight: 600;
    margin-bottom: 0;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
    padding-left: 4px;
}

.info-label {
    font-size: 14px;
}

.vehicle-info .info-value {
    font-weight: 400;
    font-size: 16px;
    padding-left: 6px;
}

.vehicle-info .info-label {
    font-weight: 600;
    font-size: 16px;
}

.add-icon {
    background: url("./../assets/images/add.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 19px auto;
    width: 18px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}

.action-column .export-container {
    border: 1px solid black;
}

.modal-content {
    border-radius: 0;
}

/* page-header setting-icon add-button*/
.span-icon {
    width: 25px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.span-icon-refresh {
    width: 25px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
}


.refresh-icon {
    background: url("./../assets/images/refresh.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 24px auto;
}

.span-icon.refresh-icon.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.add-button .button-text {
    padding-left: 5px;
}

.add-button button {
    border: none;
}

.edit-view-icon {
    background: url("./../assets/images/edit.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 19px auto;
    width: 19px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

/*modal view design edits*/
.modal-dialog .modal-content .modal-header .modal-title {
    font-weight: 600;
}

/*modal view design edits*/
.health-check .modal-content .modal-header .modal-title {
    display: inline-block;
}

.modal-dialog .modal-content .modal-header .close > span {
    display: none;
}

.modal-dialog .modal-content .modal-header .close {
    background: url("./../assets/images/close.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 24px auto;
    width: 24px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.delete-icon {
    background: url('./../assets/images/delete-icon.png') no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
}

.wrapper-gps {
    margin-left: 15px;
}

.dropdown-menu{
    overflow-y: auto;
    max-height: 200px;
}
.custom-search{
    margin-right: 15px;
}
.subheading{
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    /* padding-bottom: 10px; */
}

.approve-text {
    margin-bottom: 10px;
    margin-top: -10px;
}

.preview-img {
    height: 100px;
    width: 150px;
    margin-top: 15px;
}

.preview-img:hover{
    width: 400px;
    position: absolute;
    height: 300px;
    z-index: 1000;
    box-shadow: 1px 1px 10px 1px #ccc;
}
.download-page{
    padding-right: 6px;
}
.download-icon {
    background: url("./../assets/images/import.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 19px auto;
    width: 19px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.date-time-margin{
    margin-right: 6px;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  margin: 10px 10px 0 0;
  border: 2px solid rgb(156, 145, 145);
}
input[type="checkbox"]:checked {
  color: white;
  background-color: #f47c21;
  border: 2px solid #f47c21;
}
input[type="checkbox"]:checked::before {
  content: "\2713";
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 20px;
}

input[type="checkbox"]:focus {
  outline: none;
}
.tab-container{
    margin-top: 10px;
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content:center;
    overflow: hidden;
    border-radius: 2rem;
}
.tab-container-service-category{
    width: 30%;
    border-radius: 5px;
}
.inner-tab {
    width: 120%;
    height: 10%;
    min-height: 150px;
    background: wheat;
    margin-top: 30px;
    margin-left: -40px;
    margin-right: -30px;
    padding: 2rem 1rem;
    color: black;
    border-radius: 2rem;
    cursor: pointer;
}
ul.sub-tab {
    width: 100%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    border-radius: 2rem;
}
ul.sub-tab li {
    width: 100%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
}
ul.sub-tab li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
}
ul.sub-tab li:hover , ul.sub-tab li.activeTab{
    background: #F47C21;
    color: white;
    width: 100%;
}

.cursor-pointer td {
    cursor: pointer;
}
/* Service Category and service provider  */
.city-names-for-service-cateogory{
    display:flex;
    flex-wrap:wrap;
    width:500px;
  }
  
  .city-names-for-service-cateogory .city{
    padding: 0 8px;
  }
