.report-section .panel{
    margin-bottom: 10px;
}
.detail-view .panel{
    margin-bottom: 10px;
}
.report-section .panel-body{
    padding-bottom: 0;
}
.detail-view .panel-body{
    padding-bottom: 0;

}
.archive-Wrapper{
    background-color: white;
    padding: 20px;
}
.archive-Wrapper .row{
        padding: 10px 0px;
}
.archive-Wrapper .labelText{
    font-weight: bold;
}
.archive-Wrapper .region-selector-wrapper{
    width: 100%;
}

.archive-Wrapper .region-selector-wrapper .select-dropdown{
    width: 100%;
}
.archive-Wrapper .SingleDatePicker .SingleDatePickerInput{
    border-radius: 4px;
    border: 1px solid #ccc;
}

@media (min-width: 768px) {
.custom-range-modal{
    width: 500px;
}
}
.pull-left{
    border-bottom: none;
    margin: 0;
    padding: 10px 0px;
    padding-right: 10px;
    font-size: 27px;
    cursor: pointer;
    font-weight:100;
}