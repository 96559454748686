

.map-wrapper {
    position: relative;
}

.map-wrapper .device-detail-map {
    padding-right: 15px;
}

.select-input .Select-arrow-zone {
    display: none;
}

.select-input .Select-control {
    height: 32px;
    border-radius: 2px;
}

.select-input .Select-input {
    height: 30px;
    padding-left: 14px;
}

.select-input .Select-placeholder, .select-input .Select--single > .Select-control .Select-value {
    line-height: 30px;
    margin-left: 17px;
}

.select-input .Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-right: 0px;
}

.select-input .Select-multi-value-wrapper {
    padding-left: 14px;
}

.select-input .Select-multi-value-wrapper::before {
    background: url("../../../assets/images/search.png");
    content: '';
    width: 24px;
    height: 19px;
    display: inline-block;
    background-size: 19px;
    vertical-align: middle;
    margin-left: 5px;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    top: 6px;
}

.select-input .Select-clear-zone {
    width: 26px;
}

.select-input .Select-clear-zone::before {
    background: url("../../../assets/images/close.png");
    content: '';
    width: 24px;
    height: 17px;
    display: inline-block;
    background-size: 17px;
    vertical-align: middle;
    margin-left: 2px;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    top: 6px;
}

.select-input .Select-clear {
    display: none;
}

.select-input .Select-loading-zone {
    padding-right: 8px;
    padding-bottom: 2px;
}

.select-input .Select-menu-outer .Select-noresults {
    display: none;
}

.select-input .is-loading .Select-menu-outer {
    border: none;
}

.select-input .Select-option.is-focused {
    background-color: #F47C21;
    color: white;
}

.select-input .Select.is-open > .Select-control {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.select-input .Select-option :hover {
    background-color: #F47C21;
    color: white;
}
/*live map*/


@media (max-width: 425px) {
    .select-input {
        float: left !important;
        padding-left: 0px;
    }
}