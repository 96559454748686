.search-container span{
    margin-right: 1rem;
    cursor: pointer;
}

.detail-container-1{
    display: flex;
    justify-content: space-between;
}
.count{
    margin-left: 2rem;
}
.panic-alert-row{
    padding-left: 0.5rem;
    display: flex;
}
.panic-alert-col-1{
    padding-right: 1rem;
}
.panic-alert-col-2{
    width: 200px;
}
.actions span{
    margin-right : 1rem;
}

.panic-alert-detail-header-text{
    margin-left: 1rem;
    font-size: 1.2rem;
}