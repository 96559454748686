ul.tab-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

ul.tab-list .tab-item {
    list-style: none;
    padding: 12px 20px;
    color: #100d19;
    margin-right: 2px;
    border-bottom: 2px solid transparent;
    display: inline-block;
    cursor: pointer;
}

ul.tab-list .tab-item.active {
    color: #f47c21;
    border-bottom-color: #f47c21;
}