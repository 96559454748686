.top-navigation.navbar-default .container-fluid {
    /* background-color: #fff; */
    color: #ffffff;
    border: none;
    /* box-shadow: 0 1px 4px 0 #ddd; */
}

#page-wrapper {
    min-height: 100%;
    background-color: #eee;
    transition: all 0.7s;
}

.page-wrapper .panel {
    border-radius: 0;
    box-shadow: 0 1px 3px 0 #ddd;
    border-color: #ddd;
    position: relative;
}

@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 200px;
        z-index: 9;
        padding: 0 25px 5px 25px;

    }

    .nav-toggle #page-wrapper {
        margin: 0 0 0 75px;

    }
}

@media screen and (max-width: 767px) {
    #page-wrapper {
        padding-top: 60px;
    }
}

.dataTables_wrapper {
    position: relative;
    clear: both;
}

.clear-float {
    clear: both;
}